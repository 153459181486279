export const useEmployerSettings = () => {
  const route = useRoute();

  const slugs = [
    "werkgevers",
    "tarieven",
    "inloggen",
    "account-aanmaken",
    "mijn-account",
  ];

  const isEmployerSlug = computed(() =>
    slugs.some((page) => route.path.includes(page)),
  );

  return {
    slugs,
    isEmployerSlug,
  };
};
