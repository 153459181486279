<template>
  <div class="font-bold">
    <!-- TODO: is this the best way to have the navigation items hard-coded here? -->
    <!-- If not employer-related page -->
    <template v-if="!isEmployerSlug">
      <NuxtLink
        :to="localePath(`/vacatures`)"
        active-class="underline"
        class="transition-colors hover:underline"
        @click="scrollToTop"
      >
        {{ $t("general.allVacancies") }}
      </NuxtLink>
      <NuxtLink
        :to="localePath(`/werken-bij`)"
        active-class="underline"
        class="transition-colors hover:underline"
      >
        {{ $t("general.workAt") }}
      </NuxtLink>
      <NuxtLink
        :to="localePath(`/werkgevers`)"
        active-class="underline"
        class="transition-colors hover:underline"
      >
        {{ $t("general.employersPlaceVacancy") }}
      </NuxtLink>
    </template>
    <!-- If employer-related page -->
    <template v-else>
      <NuxtLink
        :to="localePath(`/tarieven`)"
        active-class="underline"
        class="transition-colors hover:underline"
      >
        {{ $t("general.plans") }}
      </NuxtLink>
      <NuxtLink
        :to="localePath(`/mijn-account/vacatures`)"
        active-class="underline"
        class="transition-colors hover:underline"
      >
        {{ $t("general.placeVacancy") }}
      </NuxtLink>
      <NuxtLink
        :to="localePath(`/vacatures`)"
        active-class="underline"
        class="transition-colors hover:underline"
      >
        {{ $t("general.viewLatestVacancies") }}
      </NuxtLink>
      <NuxtLink :to="localePath(`/mijn-account`)" target="_top" class="btn">
        {{ $t("general.myAccount") }}
      </NuxtLink>
    </template>
  </div>
</template>

<script setup lang="ts">
const localePath = useLocalePath();
const { isEmployerSlug } = useEmployerSettings();
const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
</script>
