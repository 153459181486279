<template>
  <footer v-if="region" class="bg-dark text-white">
    <div>
      <div class="flex flex-col sm:flex-row py-base gap-base flex-wrap">
        <SiteLogo />

        <div
          class="grid grid-cols-1 sm:grid-cols-3 gap-base lg:gap-y-base-1/2 lg:gap-x-base-2"
        >
          <!-- Discover more -->
          <div>
            <div class="font-bold text-2xl mb-2">
              {{ $t("general.discoverMore") }}
            </div>
            <FkStrapiNavigation
              v-slot="{ data }"
              :navigation="`primary`"
              class="flex flex-col gap-1"
            >
              <template v-for="item in data" :key="`nav-item-${item.title}`">
                <NuxtLink
                  v-if="
                    `over ${region?.attributes.title.toLowerCase()}` ==
                      item.title.toLowerCase() ||
                    item.title.indexOf('Over') === -1
                  "
                  :to="localePath(`/${item.path}`)"
                  class="no-underline hover:underline"
                  active-class="underline"
                  aria-current-value="page"
                >
                  {{ item.title }}
                </NuxtLink>
                <div v-else class="hidden"></div>
              </template>
            </FkStrapiNavigation>
          </div>

          <!-- Job seekers -->
          <div>
            <div class="font-bold text-2xl mb-2">
              {{ $t("general.jobSeekers") }}
            </div>
            <FkStrapiNavigation
              v-slot="{ data }"
              :navigation="`secondary`"
              class="flex flex-col gap-1"
            >
              <NuxtLink
                v-for="item in data"
                :key="`nav-item-${item.title}`"
                :to="localePath(`/${item.path}`)"
                class="no-underline hover:underline"
                active-class="underline"
                aria-current-value="page"
              >
                {{ item.title }}
              </NuxtLink>
            </FkStrapiNavigation>
          </div>

          <!-- Employers -->
          <div>
            <div class="font-bold text-2xl mb-2">
              {{ $t("general.employers") }}
            </div>
            <FkStrapiNavigation
              v-slot="{ data }"
              :navigation="`tertiary`"
              class="flex flex-col gap-1"
            >
              <NuxtLink
                v-for="item in data"
                :key="`nav-item-${item.title}`"
                :to="localePath(`/${item.path}`)"
                class="no-underline hover:underline"
                active-class="underline"
                aria-current-value="page"
              >
                {{ item.title }}
              </NuxtLink>
            </FkStrapiNavigation>
          </div>

          <!-- Other -->
          <div>
            <div class="font-bold text-2xl mb-2">
              {{ $t("general.alsoVisit") }}
            </div>
            <div class="flex flex-col gap-1">
              <template
                v-for="item in regions"
                :key="`footer-region-${item.id}`"
              >
                <!--target="_self" because of UX and rel="noopener" because of SEO-->
                <a
                  v-if="region?.attributes.title != item.attributes.title"
                  :href="
                    isDev
                      ? item.attributes.url
                          ?.replace('flyingkiwi.dev', 'localhost:3000')
                          .replace('https', 'http')
                      : item.attributes.url
                  "
                  class="no-underline hover:underline"
                  target="_self"
                  rel="noopener"
                  >{{ item.attributes.title }}</a
                >
              </template>
            </div>
          </div>

          <!-- contact -->
          <div>
            <div class="font-bold text-2xl mb-2">
              {{ $t("general.contact") }}
            </div>
            <div class="fk-prose">
              <p>
                <a href="tel:0413411024">0413 411 024</a><br />
                <a href="mailto:info@werkinmeierijstad.nl"
                  >info@werkinmeierijstad.nl</a
                >
              </p>
              <p>
                Stadhuisplein 121<br />
                5461 KR Veghel
              </p>
            </div>
            <!-- TODO: Add socials if socials are known -->
            <!-- <div class="flex gap-4 mt-4">
              <a
                v-for="social in socials"
                :key="`social-link-${social.title}`"
                :href="social.link"
                :title="social.title"
                class="no-underline hover:underline flex"
                target="_blank"
                rel="noopener"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                  class="w-5 fill-current hover:scale-125 transition-transform"
                >
                  <path :d="social.icon" />
                </svg>
              </a>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="gradient max-w-none mx-auto p-0">
      <FkStrapiNavigation
        v-slot="{ data }"
        :navigation="`legal`"
        class="py-8 flex flex-col lg:flex-row gap-x-4 gap-y-1 container mx-auto px-4 md:px-base"
      >
        &copy; {{ new Date().getFullYear() }}
        {{ region?.attributes.title }}
        <NuxtLink
          v-for="item in data"
          :key="`nav-item-${item.title}`"
          :to="localePath(`/${item.path}`)"
          active-class="underline"
          class="underline hover:underline"
          aria-current-value="page"
        >
          {{ item.title }}
        </NuxtLink>
      </FkStrapiNavigation>
      <LanguageSwitcher />
    </div>
  </footer>
</template>

<script setup lang="ts">
const localePath = useLocalePath();
const { regions, region } = useStaticData();
const isDev = computed(() => {
  return import.meta.env.DEV;
});

// const socials = [
//   {
//     link: "#",
//     title: "LinkedIn",
//     icon: "M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z",
//   },
//   {
//     link: "#",
//     title: "Instagram",
//     icon: "M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z",
//   },
//   {
//     link: "#",
//     title: "Facebook",
//     icon: "M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z",
//   },
//   {
//     link: "#",
//     title: "YouTube",
//     icon: "M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z",
//   },
// ];
</script>
