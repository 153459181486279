<template>
  <header
    class="sticky top-0 inset-x-0 gradient text-white z-50"
    :class="{ 'sm:!fixed': $route.path == '/' }"
  >
    <Disclosure v-slot="{ open, close }" as="nav">
      <div class="flex flex-wrap items-center justify-between lg:py-4">
        <SiteLogo />

        <!-- Desktop menu -->
        <SiteHeaderNavigation class="hidden space-x-base-1/2 lg:block" />

        <!-- Mobile menu toggler -->
        <DisclosureButton
          :key="`mobile-disclosure-button`"
          class="group pointer-events-auto lg:hidden"
        >
          <div
            class="relative inline-flex aspect-square items-center justify-center font-bold"
          >
            <span class="translate-y-[1px]">Menu</span>
            <svg
              class="aspect-square w-4 cursor-pointer stroke-current stroke-[3px] transition-all flex-none ml-2"
              aria-hidden="true"
            >
              <line
                x1="0%"
                y1="50%"
                x2="100%"
                y2="50%"
                class="origin-center -translate-y-1/3 transition-transform"
                :class="{ '!translate-y-0 rotate-45': open }"
              />
              <line
                x1="0%"
                y1="50%"
                x2="100%"
                y2="50%"
                class="origin-center transition-transform"
                :class="{ 'scale-0': open }"
              />
              <line
                x1="0%"
                y1="50%"
                x2="100%"
                y2="50%"
                class="origin-center translate-y-1/3 transition-transform"
                :class="{ '!translate-y-0 -rotate-45': open }"
              />
            </svg>
          </div>
        </DisclosureButton>

        <!-- Mobile dropdown menu -->
        <div class="basis-full lg:hidden">
          <TransitionExpand no-opacity>
            <DisclosurePanel>
              <SiteHeaderNavigation
                class="flex flex-col last:*:border-t last:*:border-white/30 last:*:py-base-1/2 py-base gap-base-1/2"
                @click="close"
              />
            </DisclosurePanel>
          </TransitionExpand>
        </div>
      </div>
    </Disclosure>
  </header>
</template>

<script setup lang="ts"></script>
