<template>
  <div
    v-if="!pending && data && Array.isArray(data) && data.length > 1"
    class="flex flex-wrap gap-2"
  >
    <NuxtLink
      v-for="locale in data"
      :key="locale.code"
      :to="switchLocalePath(locale.code)"
      active-class="!opacity-100 cursor-default"
      class="uppercase opacity-30 hover:opacity-100 transition-opacity"
    >
      {{ locale.code }}
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
const switchLocalePath = useSwitchLocalePath();

const { find } = useStrapi();

interface Locale {
  code: string;
  name: string;
}

const { data, pending } = useLazyAsyncData(async () => {
  const locales = (await find("i18n/locales")) as unknown as Locale[];

  return locales;
});
</script>
