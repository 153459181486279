<template>
  <!-- Logo -->
  <NuxtLink :to="'/'" target="_top" class="select-none">
    <!--Fixed height and widths on SVG asset because of LightHouse improvement-->
    <img
      class="h-[2.25rem] md:h-[2.75rem] w-[10.75rem] md:[13rem] invert brightness-0 object-contain object-left"
      :src="`${logo}`"
      :alt="region?.attributes?.title"
    />
  </NuxtLink>
</template>

<script setup lang="ts">
const { region, logo } = useStaticData();
</script>
